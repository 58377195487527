import { BodyText3, Button, Heading3 } from '@lumoslabs/lumosity-storybook'
import Link from 'next/link'
import styled from 'styled-components'

import QueryLoader from '~/components/QueryLoader'
import SkeletonLoader from '~/components/ui/SkeletonLoader'
import WorkoutRing from '~/components/workout/WorkoutRing'
import { EventClick, EventScreenName } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import { useGetUserQuery } from '~/graphql/generated/schema'
import useLocalDate from '~/hooks/useLocalDate'
import useTodaysWorkout, { WorkoutMetaNotNull } from '~/hooks/useTodaysWorkout'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { nonTokenColors, workoutModeHoveColor } from '~/styles/color'

export interface WorkoutCardProps {
  workoutMeta: WorkoutMetaNotNull
  firstName: string
}

const WorkoutCard: React.FC<WorkoutCardProps> = ({ workoutMeta, firstName }) => {
  const t = useTranslationForNamespace('home')

  const { trackCta } = useTrackClick()
  const { dateFormatted } = useLocalDate()

  // Prevent the remaining game count going below 0 when the sub status goes from Premium to Free
  const { remainingGameCount, nextUnplayedGame, isWorkoutComplete, isWorkoutStarted, mode } = workoutMeta
  const nextGameSlug = nextUnplayedGame?.game?.slug
  const gameUrl = nextGameSlug ? `/workout` : '/'

  const handleClick = (text: string) => {
    trackCta({
      text: text,
      type: EventClick.types.Button,
      destination: EventScreenName.Pregame,
      click_name: EventClick.names.HomeWorkoutPlay,
    })
  }

  const WorkoutCardContent = () => {
    if (isWorkoutComplete) {
      const headingText = t('workout:completed.title', { firstName: firstName })
      const subheadingText = t('workout:completed.caption')

      return (
        <Content $isCompleted={true}>
          <InfoSection>
            <Heading3>{headingText}</Heading3>
            <CardCaption>{subheadingText}</CardCaption>
          </InfoSection>
        </Content>
      )
    } else {
      let headingText = t(`workout:modes.${mode}.title`)
      let ctaText = t(`common:buttons.begin`)
      let gamesRemainingText = t('workoutCard.subheadings.today.gamesRemainingInitial', { count: remainingGameCount })

      if (isWorkoutStarted) {
        headingText = t(`workout:modes.${mode}.continueText`)
        ctaText = t(`common:buttons.continue`)
        gamesRemainingText = t('workoutCard.subheadings.today.gamesRemaining', { count: remainingGameCount })
      }

      return (
        <Content $isCompleted={false}>
          <InfoSection>
            <Heading3>{headingText}</Heading3>
            <CardCaption>
              {dateFormatted}&nbsp;&nbsp;|&nbsp;&nbsp;{gamesRemainingText}
            </CardCaption>
          </InfoSection>
          <WorkoutLongButton href={gameUrl} passHref={true} prefetch={false} onClick={() => handleClick(ctaText)}>
            <Button kind='primary' wide={true}>
              {ctaText}
            </Button>
          </WorkoutLongButton>
        </Content>
      )
    }
  }

  return (
    <WorkoutCardContainer $isCompleted={isWorkoutComplete} $mode={mode}>
      <WorkoutRingContainer>
        <WorkoutRing workoutMeta={workoutMeta} />
      </WorkoutRingContainer>
      <WorkoutCardContent />
    </WorkoutCardContainer>
  )
}

const WorkoutCardWrapper = () => {
  const { workoutMeta, loading, error } = useTodaysWorkout()
  const { data: userQueryData } = useGetUserQuery({ fetchPolicy: 'cache-only' })
  const firstName = userQueryData?.me?.firstName ?? ''

  return (
    <QueryLoader data={workoutMeta} loading={loading} error={error} LoadingComponent={<Skeleton />}>
      {workoutMeta && <WorkoutCard workoutMeta={workoutMeta} firstName={firstName} />}
    </QueryLoader>
  )
}
export default WorkoutCardWrapper

const Skeleton = styled(SkeletonLoader)`
  width: min(592px, 100%);
  height: 240px;
`

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`

const WorkoutLongButton = styled(Link)`
  max-height: 52px;
  display: inline-flex;
  align-items: centre;
  width: min(100%, 240px);
`

const Content = styled.div<{ $isCompleted: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  color: ${({ theme }) => theme.colorTokens.text['text-default']};
  padding: ${(props) => (props.$isCompleted ? '0px' : '0px 32px')};
`

const WorkoutCardContainer = styled.div<{ $isCompleted: boolean; $mode: WorkoutModeTypes }>`
  overflow: hidden;
  position: relative;
  max-width: min(100%, 592px);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  background: ${({ theme }) => theme.colorTokens.surface['surface-default']};
  box-shadow: ${(props) =>
    props.$isCompleted
      ? `0px 0px 0px 1px ${nonTokenColors.neutralCool90}`
      : `2px 2px 8px 2px ${nonTokenColors.neutralCool90}`};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    flex-direction: column;
    gap: 8px;
    text-align: center;
  }
  &:hover {
    box-shadow: ${({ $isCompleted, $mode }) =>
      $isCompleted ? undefined : `4px 4px 40px 4px ${workoutModeHoveColor[$mode]}`};
  }
`

const WorkoutRingContainer = styled.div`
  width: 148px;
  height: 148px;
  display: flex;
  justify-content: center;
  padding: 10px;
`
const CardCaption = styled(BodyText3)`
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
`
