import React, { useContext, useState } from 'react'

import { BodyText1SemiBold, BodyText3, MicroText2 } from '@lumoslabs/lumosity-storybook'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'

import QueryLoader from '~/components/QueryLoader'
import SkeletonLoader from '~/components/ui/SkeletonLoader'
import { ChangeWorkoutModal, UnlockWorkouts } from '~/components/workout/WorkoutModals'
import { ActiveWorkoutCookie } from '~/constants'
import { AuthContext } from '~/context/AuthContext'
import useTodaysWorkout, { WorkoutMeta, useAvailableWorkouts } from '~/hooks/useTodaysWorkout'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { WorkoutModeIcon } from '~/images/Workout/Mode'
import StatusCompletedIcon from '~/images/Workout/StatusMode_completed.svg'
import StatusLockedIcon from '~/images/Workout/StatusMode_locked.svg'

const ModalNames = {
  None: 'None',
  UnlockWorkouts: 'UnlockWorkouts',
  ChangeWorkout: 'ChangeWorkout',
} as const

type ActiveModalTypes =
  | { name: typeof ModalNames.None }
  | { name: typeof ModalNames.UnlockWorkouts }
  | { name: typeof ModalNames.ChangeWorkout; workoutMode: WorkoutModeTypes }

export const WorkoutModeList = () => {
  const router = useRouter()
  const { lang } = useTranslation()
  const isEnglish = lang === 'en'
  const [, setCookie] = useCookies([ActiveWorkoutCookie])
  const t = useTranslationForNamespace('workout')
  const { hasPremium } = useContext(AuthContext)
  const [activeModal, setActiveModal] = useState<ActiveModalTypes>({ name: ModalNames.None })
  const { allWorkoutModes, ...availableWorkoutData } = useAvailableWorkouts()
  const { workoutMeta, ...todayWorkoutData } = useTodaysWorkout()

  const unlockModalClick = {
    onShow: () => setActiveModal({ name: ModalNames.UnlockWorkouts }),
    onCancel: () => setActiveModal({ name: ModalNames.None }),
  }

  const changeModalCLick = {
    onShow: (workoutMode: WorkoutModeTypes, workout: WorkoutMeta) => {
      if (workout?.isWorkoutComplete) return
      if (!hasPremium) return unlockModalClick.onShow()
      setActiveModal({ name: ModalNames.ChangeWorkout, workoutMode })
    },
    onCancel: () => setActiveModal({ name: ModalNames.None }), // reset the data
  }

  const onWorkoutChange = (workoutMode: WorkoutModeTypes) => {
    setCookie(
      ActiveWorkoutCookie,
      { mode: workoutMode },
      {
        path: '/',
        // expires everyday at midnight, thus for next day, user will start with classic mode
        expires: new Date(new Date().setHours(24, 0, 0, 0)),
      },
    )
    router.push('/workout')
  }

  const query = {
    loading: availableWorkoutData.loading || todayWorkoutData.loading,
    error: availableWorkoutData.error || todayWorkoutData.error,
    data: allWorkoutModes && workoutMeta,
  } as const

  const changeWorkoutData = Object.entries(allWorkoutModes).find(([workoutMode, _workout]) => {
    if (!(activeModal.name === ModalNames.ChangeWorkout && activeModal.workoutMode)) return false
    return workoutMode === activeModal.workoutMode
  })

  return (
    <>
      <QueryLoader {...query} LoadingComponent={<Skeleton />}>
        <ModeList>
          {Object.entries(allWorkoutModes).map(([workoutMode, workout]) => {
            if (workoutMode === workoutMeta?.mode) return null // don't show the current active workout
            if (!isEnglish && workoutMode === 'language') return null // don't show language mode for non-english users
            const isWorkoutComplete = workout?.isWorkoutComplete || false
            const isWorkoutStarted = workout?.isWorkoutStarted || false
            const totalGamePlayed = workout?.totalGamePlayed || 0
            const totalGamesCount = workout?.totalGamesCount || 0
            return (
              <ModeItem
                key={workoutMode}
                onClick={() => changeModalCLick.onShow(workoutMode as WorkoutModeTypes, workout)}
                role='button'
                tabIndex={0}
                $disable={isWorkoutComplete}
              >
                <ItemIcon>
                  <ItemIconMain>
                    <WorkoutModeIcon mode={workoutMode as WorkoutModeTypes} />
                  </ItemIconMain>
                  <ItemIconStatus>
                    {(() => {
                      if (!hasPremium) return <StatusLockedIcon title='' />
                      if (isWorkoutComplete) return <StatusCompletedIcon title='' />
                      return null
                    })()}
                  </ItemIconStatus>
                </ItemIcon>
                <ModeItemContent>
                  <BodyText1SemiBold>{t(`workout:modes.${workoutMode}.label`)}</BodyText1SemiBold>
                  <BodyText3>{t(`workout:modes.${workoutMode}.caption`)}</BodyText3>
                </ModeItemContent>
                {!isWorkoutComplete && isWorkoutStarted && (
                  <ItemProgress>
                    <ProgressCircle />
                    <ProgressText>
                      {totalGamePlayed}/{totalGamesCount}
                    </ProgressText>
                  </ItemProgress>
                )}
              </ModeItem>
            )
          })}
        </ModeList>
      </QueryLoader>
      {activeModal.name === ModalNames.UnlockWorkouts && <UnlockWorkouts onCancel={unlockModalClick.onCancel} />}

      {activeModal.name === ModalNames.ChangeWorkout && activeModal.workoutMode && changeWorkoutData && (
        // keep this outside QueryLoader, as it not be unmounted when loading state changes
        <ChangeWorkoutModal
          workoutMode={activeModal.workoutMode}
          workout={changeWorkoutData[1]}
          loading={todayWorkoutData.loading}
          onWorkoutChange={() => onWorkoutChange(activeModal.workoutMode)}
          onCancel={unlockModalClick.onCancel}
        />
      )}
    </>
  )
}

const Skeleton = () => {
  return (
    <ModeList>
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </ModeList>
  )
}

const SkeletonItem = styled(SkeletonLoader)`
  width: 100%;
  height: 120px;
  border-radius: 8px;
`

const ModeList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  ${({ theme }) => theme.mediaQuery.maxWidth.tablet} {
    grid-template-columns: 1fr;
  }
`
const ModeItem = styled.div<{ $disable: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colorTokens.border['border-subdued']};
  background: ${({ theme }) => theme.colorTokens.surface['surface-default']};

  pointer-events: ${({ $disable }) => ($disable ? 'none' : 'auto')};
  cursor: ${({ $disable }) => ($disable ? 'none' : 'pointer')};
  &:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06), 0px 3px 19px 0px rgba(0, 0, 0, 0.15);
  }
`
const ModeItemContent = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemIcon = styled.div`
  display: flex;
  flex-direction: column;
  width: 72px;
  height: 72px;
  align-items: center;
  justify-content: center;
  position: relative;
`
const ItemIconMain = styled.div`
  svg {
    width: 60px;
    height: 60px;
    font-size: 60px;
  }
`

const ItemIconStatus = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
`

const ItemProgress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: absolute;
  top: 8px;
  right: 16px;
`

const ProgressCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background: ${({ theme }) => theme.colorTokens.accent.secondary};
`
const ProgressText = styled(MicroText2)`
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
`
