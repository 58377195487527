import { BadgeIcon, BodyText2, Button, Heading3 } from '@lumoslabs/lumosity-storybook'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { StyledModalContainer, StyledModalContentScrollable } from '~/components/modals/StyledModal'
import { WorkoutMeta } from '~/hooks/useTodaysWorkout'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import LockIcon from '~/images/icons/SystemOutlined/Lock.svg'
import UnLockIcon from '~/images/icons/SystemOutlined/Unlock.svg'
import { WorkoutModeIcon } from '~/images/Workout/Mode'

interface ChangeWorkoutModalProps {
  onCancel: () => void
  onWorkoutChange: () => void
  workoutMode: WorkoutModeTypes
  workout: WorkoutMeta
  loading?: boolean
}
export const ChangeWorkoutModal = ({
  workoutMode,
  workout,
  onCancel,
  onWorkoutChange,
  loading = false,
}: ChangeWorkoutModalProps) => {
  const t = useTranslationForNamespace('workout')
  const isWorkoutStarted = workout?.isWorkoutStarted || false
  const remainingGameCount = workout?.remainingGameCount || 0
  const gamesRemainingText = t('home:workoutCard.subheadings.today.gamesRemaining', { count: remainingGameCount })

  return (
    <Modal isOpen={true} onBackgroundClick={onCancel}>
      <ModalContent>
        <ModeContent>
          <ItemIconMain>
            <WorkoutModeIcon mode={workoutMode as WorkoutModeTypes} />
          </ItemIconMain>
          <WModeTitle>{t(`modes.${workoutMode}.title`)}</WModeTitle>
          <WModeCaption>{t(`modes.${workoutMode}.caption`)}</WModeCaption>
          {isWorkoutStarted ? <WModeInfo>{gamesRemainingText}</WModeInfo> : null}
          <ChangeWorkoutActionButton kind='primary' onClick={onWorkoutChange} disabled={loading}>
            {(() => {
              if (loading) return t('common:loadingWithEllipses')
              if (isWorkoutStarted) return t('common:buttons.continue')
              return t('common:buttons.start')
            })()}
          </ChangeWorkoutActionButton>
        </ModeContent>
      </ModalContent>
    </Modal>
  )
}

const ChangeWorkoutActionButton = styled(Button)`
  margin-top: 12px;
`

const WModeTitle = styled(Heading3)`
  margin-bottom: 4px;
`

const WModeCaption = styled(BodyText2)`
  margin-bottom: 4px;
`
const WModeInfo = styled(BodyText2)`
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
`
const ModeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ItemIconMain = styled.div`
  svg {
    width: 72px;
    height: 72px;
    font-size: 72px;
  }
  margin-bottom: 16px;
`

interface UnlockWorkoutsModalProps {
  onCancel: () => void
}
export const UnlockWorkouts = ({ onCancel }: UnlockWorkoutsModalProps) => {
  const t = useTranslationForNamespace('workout')
  const router = useRouter()

  return (
    <Modal isOpen={true} onBackgroundClick={onCancel}>
      <ModalContent>
        <LockIconBig />
        <Heading3>{t('unlockWorkoutModal.title')}</Heading3>
        <DescriptionUnlock>{t('unlockWorkoutModal.caption')}</DescriptionUnlock>
        <Actions>
          <Button wide={true} kind='primary' onClick={() => router.push('/subscribe')} rightBadge={<UnlockBadge />}>
            {t('common:buttons.unlockPremium')}
          </Button>
          <Button wide={true} kind='secondary' onClick={onCancel}>
            {t('common:buttons.close')}
          </Button>
        </Actions>
      </ModalContent>
    </Modal>
  )
}

const Modal = styled(StyledModalContainer)`
  max-width: min(90vw, 592px);
`
const ModalContent = styled(StyledModalContentScrollable)`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(100%, 296px);
  gap: 12px;
`

const LockIconBig = styled(LockIcon).attrs({ title: 'lock' })`
  font-size: 40px;
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
`

const UnLockIconStyled = styled(UnLockIcon).attrs({ title: 'unlock' })`
  font-size: 32px;
  width: 32px;
  height: 32px;
`
const UnlockBadge = () => {
  return (
    <BadgeIcon>
      <UnLockIconStyled />
    </BadgeIcon>
  )
}

const DescriptionUnlock = styled(BodyText2)`
  margin: 32px auto;
`
