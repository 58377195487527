import { useContext, useState } from 'react'

import { MicroText1, Subheading1 } from '@lumoslabs/lumosity-storybook'
import styled, { css } from 'styled-components'

import { UnlockWorkouts } from '~/components/workout/WorkoutModals'
import { WorkoutModeList } from '~/components/workout/WorkoutModeList'
import { AuthContext } from '~/context/AuthContext'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import LockIcon from '~/images/icons/SystemOutlined/Lock.svg'
import UnlockIcon from '~/images/icons/SystemOutlined/Unlock.svg'

const ModalNames = {
  None: 'None',
  UnlockWorkouts: 'UnlockWorkouts',
}

export const WorkoutModes = () => {
  const { hasPremium } = useContext(AuthContext)
  const t = useTranslationForNamespace('workout')
  const [activeModal, setActiveModal] = useState(ModalNames.None)

  const UnlockModalClicks = {
    onShow: () => setActiveModal(ModalNames.UnlockWorkouts),
    onCancel: () => setActiveModal(ModalNames.None),
  }

  return (
    <Container>
      <HeaderSection>
        <Subheading1>{t('modeTitle')}</Subheading1>
        {!hasPremium && (
          <UnlockButton onClick={UnlockModalClicks.onShow}>
            <MicroText1>{t('common:buttons.unlock')}</MicroText1>
            <UnlockedIcon title='unlock' className='unlockButton--unlockedIcon' />
            <LockedIcon title='lock' className='unlockButton--lockedIcon' />
          </UnlockButton>
        )}
      </HeaderSection>
      <WorkoutModeList />

      {activeModal === ModalNames.UnlockWorkouts && <UnlockWorkouts onCancel={UnlockModalClicks.onCancel} />}
    </Container>
  )
}

const Container = styled.div`
  width: min(100%, 592px);
  margin-top: 40px;
`

const HeaderSection = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const UnlockButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 2px 8px 2px 12px;
  border: none;
  outline: none;
  border-radius: 400px;
  background-color: ${({ theme }) => theme.colorTokens.accent.success};
  color: ${({ theme }) => theme.colorTokens.text['text-on-interactive']};
  cursor: pointer;

  .unlockButton--unlockedIcon {
    display: none;
  }
  .unlockButton--lockedIcon {
    display: block;
  }

  &:hover .unlockButton--unlockedIcon {
    display: block;
  }
  &:hover .unlockButton--lockedIcon {
    display: none;
  }
`
const UnlockButtonIcon = css`
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: ${({ theme }) => theme.colorTokens.text['text-on-interactive']};
`

const UnlockedIcon = styled(UnlockIcon)`
  ${UnlockButtonIcon}
`
const LockedIcon = styled(LockIcon)`
  ${UnlockButtonIcon}
`
